import axios from "axios";
import { toast } from "react-toastify";
import { getCall, postCall } from "../../app/axiosConfig";

// fetch all product
export const getAllJobs = () => async (dispatch) => {
	try {
		dispatch({
			type: "jobCreateRequest",
		});
		const url = `/landingpage/jobs`;
		const { data } = await getCall(url);

		console.log(data);

		dispatch({
			type: "jobgetAllSuccess",
			payload: data.data,
		});
	} catch (error) {
		dispatch({
			type: "jobFetchFail",
			payload: error.response.data.message,
		});
	}
};
