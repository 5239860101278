// Redux/reducers/Jobs.js

import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  error: false,
  allEmployers: [],
};

export const employerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("employersCreateRequest", (state) => {
      state.isLoading = true;
    })
    .addCase("employersgetAllSuccess", (state, action) => {
      state.isLoading = false;
      state.allEmployers = action.payload;
    })
    .addCase("employersFetchFail", (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
});
