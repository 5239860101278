// Redux/reducers/Jobs.js

import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  error: false,
  allJobs: [],
};

export const JobReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("jobCreateRequest", (state) => {
      state.isLoading = true;
    })
    .addCase("jobgetAllSuccess", (state, action) => {
      state.isLoading = false;
      state.allJobs = action.payload;
    })
    .addCase("jobFetchFail", (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
});
