import { createAction, createReducer } from "@reduxjs/toolkit";

const Searchbarfield = createAction("Searchbarfield");

// reducers.js
const initialState = {
  searchboxclick: false,
};

export const buttonClickReducer = createReducer(initialState, (builder) => {
  builder.addCase(Searchbarfield, (state, action) => {
    state.searchboxclick = action.payload;
  });
});
